import React from 'react'
import {useSpring, animated} from 'react-spring'

const Footer = () => {

    const fadeFooter = useSpring({ 
      from: { opacity: 0},
      delay: 1400,
      opacity: 1,
      config: {duration : 500} 
    })

    return (
        <animated.footer className="md:my-48 my-16" style={fadeFooter}>
          <div className="container mx-auto px-8">
            {/* <div className="footNote grid grid-cols-12 gap-4 md:mb-20 mb-10">
              <div className="foontNote_top col-span-10 md:col-span-5">
                <h4 className="tracking-tight md:text-2xl text-xl">Always happy to chat</h4>
                <small className="tracking-tight md:text-2xl text-xl text-gray-600">Inquiries about work, share ideas or just want to get to know me. I’m all ears.</small>
              </div>
            </div> */}
            <div  className="rdwn-footer">
              <div className="footer-content flex">
                <ul className="rdwn-socials flex">
                    {/* <li className="social-link"><a href="mailto:rosanusi@gmail.com"  rel="noopener noreferrer" target="_blank">Email</a></li> */}
                    <li className="social-link"><a href="https://sidenote.ridwansanusi.com/"  rel="noopener noreferrer" target="_blank">Sidenote</a></li>
                    <li className="social-link"><a href="https://twitter.com/busolasanusi" rel="noopener noreferrer" target="_blank">Twitter</a></li>
                    <li className="social-link"><a href="https://www.linkedin.com/in/busolarsanusi/" rel="noopener noreferrer" target="_blank">Linkedin</a></li>
                    <li className="social-link"><a href="https://github.com/rosanusi?tab=repositories" rel="noopener noreferrer" target="_blank">Github</a></li>
                    {/* <li className="social-link"><a href="https://dribbble.com/busolasanusi" rel="noopener noreferrer" target="_blank">Dribbble</a></li> */}
                    {/* <li><a href="https://open.spotify.com/user/rosanusi?si=Yzurq8VvQlq_mPCxwT83Sw" rel="noopener noreferrer" target="_blank">Spotify</a></li> */}
                    {/* <li><a href="https://www.instagram.com/busolasanusi/" rel="noopener noreferrer" target="_blank">Instagram</a></li> */}
                </ul>          
                <div className="copyright">
                  <span>&copy; 2014 - 2021</span>
                </div>    
              </div>
              <div className="footer-background"></div>
            </div>
          </div>
        </animated.footer>
    )
}


export default Footer